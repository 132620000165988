import React, { useState } from "react";
import useAxios, { configure } from "axios-hooks";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import useDayjsFormat from "../../hooks/useDayjsFormat";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import InputOption from "../inputoption/InputOption";
import { $authHost, axiosConfig } from "../../common/api";
import {
  isOtpCode,
  isValidCorrectionValue,
  isPdfPath,
  getFioRequisiteData,
  getNormalRequisiteData,
  formatFinance,
  getLkAlias,
  previewImageExt,
} from "../../common/utils";

import { CDN_PUBLIC_URL, TRADE_MODE } from "../../common/constants";
const options = [
  //  "Вы отправили фейк чек. В случае, если Вы не согласны с решением, просим отправить видео-подтверждение об оплате в техническую поддержку.",
  // "Имя получателя в чеке не соответствует имени получателя на устройстве.",
  // "Сумма в чеке меньше, чем в заявке.",
  // "Старый чек. Необходимо отправить видео-подтверждение об оплате в техническую поддержку.",

  "Данная транзакция не была найдена. В случае, если Вы не согласны с решением, просим отправить видео-подтверждение об оплате в техническую поддержку.",
  "Имя получателя в чеке не соответствует имени получателя на устройстве.",
  "Сумма в чеке меньше, чем в заявке.",
  "К сожалению, мы не можем проверить данную транзакцию. Необходимо отправить видео-подтверждение об оплате в техническую поддержку.",
  "Вы отправили фейк-чек. При повторной отправке фейк-чека мы будем вынуждены заблокировать Ваш аккаунт.",
  "Чек принадлежит другому платежу, который был зачислен.",
];

const optionDone = [
  "Депозит успешно начислен.",
  "Депозит успешно начислен + Компенсация",
];

const ModalViewAppeal = ({ active, setActive, appealData }) => {
  const [otpCode, setOtpCode] = useState("");
  const [correctionValue, setCorrectionValue] = useState("");
  const [commentText, setCommentText] = useState("");
  const [commentDoneText, setCommentDoneText] = useState(optionDone[0]);
  const [actionType, setActionType] = useState("");

  useGlobalScrollLock(active);

  const { getFullTimeMsk, getFullDateMsk } = useDayjsFormat();

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: "/trader/appeals/changestatus",
      method: "POST",
    },
    axiosConfig
  );

  const [{ loading: loadingStatus }, executePostStatus] = useAxios(
    {
      url: "/trader/deals/changestatus",
      method: "POST",
    },
    axiosConfig
  );

  let errors = {
    verify_2fa: isOtpCode(otpCode)
      ? null
      : {
          message: "Допустимо только 6 цифр",
        },
    verify_correction: isValidCorrectionValue(
      correctionValue,
      appealData?.amount_to_pay
    )
      ? null
      : {
          message: "Не должно быть меньше суммы платежа",
        },
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  const handleCorrectionValue = (e) => {
    setCorrectionValue(e);
    setCommentDoneText(e === "" ? optionDone[0] : optionDone[1]);
  };

  const handleOnClose = () => {
    setOtpCode("");
    setCommentText("");
    setCommentDoneText(optionDone[0]);
    setCorrectionValue("");
    setActionType("");
    setActive(false);
  };

  const handleChangeStatusAppeal = () => {
    executePost({
      data: {
        appeal_id: appealData.id,
        trader_note: commentText,
        state: "cancel",
      },
    })
      .then((data) => {
        handleOnClose();
        toast.success("Статус апелляции успешно изменён");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  const handleChangeStatusDeal = () => {
    console.log("handleChangeStatusDeal", {
      deal_id: appealData.deal_id,
      otp_code: otpCode,
      correction: correctionValue,
      trader_note: commentDoneText,
      state: "close",
    });

    executePostStatus({
      data: {
        deal_id: appealData.deal_id,
        otp_code: otpCode,
        correction: correctionValue,
        trader_note: commentDoneText,
        state: "close",
      },
    })
      .then((data) => {
        handleOnClose();
        toast.success("Статус апелляции успешно изменён");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  const handleCommentText = (e) => {
    setCommentText(e);
  };

  if (!active || !appealData || !appealData?.payment_id) {
    return null;
  }
  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal8"
      onClick={() => {
        setOtpCode("");
        setCommentText("");
        setCommentDoneText(optionDone[0]);
        setCorrectionValue("");
        setActionType("");

        setActive(false);
      }}
    >
      <div
        className="dialog"
        onClick={(e) => e.stopPropagation(e)}
        style={{
          maxWidth: "1000px",
          margin: "20px auto",
        }}
      >
        <div className="dialog__head">
          <div className="dialog__head-title">Просмотр апелляции</div>
          <div
            className="dialog__head-close"
            id="modal8Close"
            onClick={() => {
              setOtpCode("");
              setCommentText("");
              setCommentDoneText(optionDone[0]);
              setCorrectionValue("");
              setActionType("");
              setActive(false);
            }}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div
          className="dialog__body"
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 150px)",
          }}
        >
          <div
            style={{
              width: "400px",
            }}
          >
            {TRADE_MODE && appealData?.status === 0 && (
              <>
                {actionType === "" && (
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <h5>Примите решение</h5>

                    <button
                      style={{ margin: 10 }}
                      className="btn btn-xs btn-draft"
                      onClick={() => {
                        setActionType("reject");
                      }}
                    >
                      Отклонить
                    </button>
                    <button
                      style={{ margin: 10 }}
                      className="btn btn-xs btn-success"
                      onClick={() => {
                        setActionType("accept");
                      }}
                    >
                      Принять
                    </button>
                  </div>
                )}
                {actionType === "reject" && (
                  <div>
                    <div className="custom-input custom-input_block custom-input_block-view">
                      <label htmlFor="appeal-note">
                        Комментарий к отклонению
                      </label>

                      <InputOption
                        key={"InputOptionCancel"}
                        style={{
                          lineHeight: 1.5,
                          paddingTop: 10,
                          paddingBottom: 10,
                          color: "white",
                          backgroundColor: "#1D2129",
                          border: "none",
                          outline: "none",
                        }}
                        options={options}
                        onValueChange={handleCommentText}
                      />
                    </div>
                    <button
                      className="btn btn-success"
                      disabled={loading || commentText === ""}
                      onClick={() => {
                        handleChangeStatusAppeal();
                      }}
                      style={{ marginTop: 40 }}
                    >
                      Подтвердить
                    </button>
                  </div>
                )}

                {actionType === "accept" && (
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <h4>Принять апелляцию</h4>

                    <div
                      className="custom-input custom-input_block custom-input_block-view"
                      style={{ marginTop: 30, marginBottom: 30 }}
                    >
                      <label htmlFor="verify-2fa">
                        Введи 6-ти значный OTP код
                      </label>
                      <input
                        name="verify-2fa"
                        type="text"
                        id="verify-2fa"
                        placeholder="123123"
                        autoComplete="off"
                        onChange={(e) => setOtpCode(e.target.value)}
                        value={otpCode}
                      />
                      {getErrorBlock(errors?.verify_2fa)}
                    </div>
                    <div
                      className="custom-input custom-input_block custom-input_block-view"
                      style={{ marginTop: 30 }}
                    >
                      <label htmlFor="verify-correction">
                        Коррекция суммы (опционально)
                      </label>
                      <input
                        name="verify-correction"
                        type="text"
                        id="verify-correction"
                        placeholder={appealData?.amount_to_pay}
                        autoComplete="off"
                        onChange={(e) => handleCorrectionValue(e.target.value)}
                        value={correctionValue}
                      />
                      {getErrorBlock(errors?.verify_correction)}
                    </div>

                    <button
                      className="btn btn-success"
                      disabled={
                        loadingStatus ||
                        errors?.verify_correction ||
                        errors?.verify_2fa
                      }
                      onClick={() => {
                        handleChangeStatusDeal();
                      }}
                      style={{ marginTop: 40 }}
                    >
                      Подтвердить
                    </button>
                  </div>
                )}
              </>
            )}

            <div className="custom-input custom-input_block custom-input_block-view">
              <label htmlFor="appeal-payment-bank_profile_id">ЛК:</label>
              <input
                value={getLkAlias(appealData?.bank, appealData?.id_alias)}
                disabled
                type="text"
                id="appeal-payment-bank_profile_id"
                autoComplete="off"
              />
            </div>

            <div className="custom-input custom-input_block custom-input_block-view">
              <label htmlFor="appeal-payment-fio">ФИО:</label>
              <input
                value={getFioRequisiteData(appealData?.payment_note)}
                disabled
                type="text"
                id="appeal-payment-fio"
                autoComplete="off"
              />
            </div>

            <div className="custom-input custom-input_block custom-input_block-view">
              <label htmlFor="appeal-payment-sum">Сумма платежа:</label>
              <input
                value={formatFinance(appealData?.amount_to_pay) + " ₽"}
                disabled
                type="text"
                id="appeal-payment-sum"
                autoComplete="off"
              />
            </div>

            <div className="custom-input custom-input_block custom-input_block-view">
              <label htmlFor="appeal-payment-method">
                Метод и реквизиты платежа:
              </label>
              <input
                value={
                  appealData?.method_group +
                  ": " +
                  getNormalRequisiteData(appealData?.payment_note)
                }
                disabled
                type="text"
                id="appeal-payment-method"
                autoComplete="off"
              />
            </div>

            <div className="custom-input custom-input_block custom-input_block-view">
              <label htmlFor="appeal-payment-id">ID платежа:</label>
              <input
                value={appealData?.payment_id}
                disabled
                type="text"
                id="appeal-payment-id"
                autoComplete="off"
              />
            </div>

            <div className="custom-input custom-input_block-view">
              <label htmlFor="appeal-payment-expired">
                Даты создания и истечения сделки:
              </label>
              <input
                value={
                  getFullDateMsk(appealData?.trade_createdAt) +
                  ", " +
                  getFullTimeMsk(appealData?.trade_createdAt) +
                  " - " +
                  getFullDateMsk(appealData?.expired) +
                  ", " +
                  getFullTimeMsk(appealData?.expired)
                }
                disabled
                type="text"
                id="appeal-payment-expired"
                autoComplete="off"
              />
            </div>

            {appealData?.note && (
              <div className="custom-input custom-custom-input_block-view">
                <label htmlFor="appeal-note">Комментарий:</label>
                <TextareaAutosize
                  name="appeal-note"
                  id="appeal-note"
                  style={{
                    lineHeight: 1.5,
                    paddingTop: 10,
                    paddingBottom: 10,
                    color: "white",
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                  }}
                  cacheMeasurements
                  readOnly={true}
                  value={appealData?.note}
                />
              </div>
            )}

            {appealData?.trader_note && (
              <div className="custom-input custom-input_block custom-input_block-view">
                <label style={{ color: "red" }} htmlFor="appeal-trader-note">
                  Причина отклонения:
                </label>
                <TextareaAutosize
                  name="appeal-trader-note"
                  id="appeal-trader-note"
                  style={{
                    lineHeight: 1.5,
                    paddingTop: 10,
                    paddingBottom: 10,
                    color: "white",
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                  }}
                  cacheMeasurements
                  readOnly={true}
                  value={appealData?.trader_note}
                />
              </div>
            )}
          </div>
          <div
            style={{
              maxWidth: "470px",
            }}
          >
            <img
              width={"100%"}
              src={CDN_PUBLIC_URL + previewImageExt(appealData?.url)}
              style={{
                borderRadius: 10,
                marginTop: 10,
              }}
            />
            {/* isPdfPath(appealData?.url) ? (
              <embed
                width={"100%"}
                height={500}
                src={CDN_PUBLIC_URL + appealData?.url}
                style={{
                  borderRadius: 10,
                  marginTop: 10,
                }}
              />
            ) : (
             null
            )
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalViewAppeal;
