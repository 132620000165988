import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { generalBankList } from "../common/constants";
import { $authHost, axiosConfig } from "../common/api";
import {
  formatFinance,
  getMainRequisiteData,
  getLkAlias,
} from "../common/utils";
import Pagination from "./Pagination";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Spinner from "./spinner/Spinner";
import ToggleButton from "./togglebutton/ToggleButton";
import ModalAddRequisite from "./modal/ModalAddRequisite";
import ModalEditRequisite from "./modal/ModalEditRequisite";
import LinearProgressBar from "./linearprogressbar/LinearProgressBar";
import { AddShopPlusIcon, EditIcon, ArchiveIcon } from "./Icons";
import ModalAddBankProfile from "./modal/ModalAddBankProfile";
import ModalAddRequisiteProfileNew from "./modal/ModalAddRequisiteProfileNew";
import ModalDeviceToArchive from "./modal/ModalDeviceToArchive";

const TradeBankProfiles = () => {
  const [modalActive, setModalActive] = useState(false);
  const [modal2Active, setModal2Active] = useState(false);
  const [modal3Active, setModal3Active] = useState(false);
  const [modalToArchiveActive, setModalToArchiveActive] = useState(false);
  const [selectedBankProfile, setSelectedBankProfile] = useState(false);

  const [device, setDevice] = useState(0);
  const [editDitails, setEditDitails] = useState({});
  const [modalEditActive, setModalEditActive] = useState(false);
  const [bankProfileData, setBankProfileData] = useState({});
  const [pageBankProfiles, setPageBankProfiles] = useState(1);
  const [trigger, setTrigger] = useState(0);
  const count = 10;
  const bankProfilesStatus = ["Отключен", "Доступен"];

  const getBankProfileStatus = (available) => {
    const index = parseInt(available);
    if (index >= 0 && index < bankProfilesStatus.length) {
      return bankProfilesStatus[index];
    } else {
      return "Архивный";
    }
  };

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: "/trader/bankprofiles",
      method: "POST",
    },
    axiosConfig
  );

  const [{ loading: loadingBankProfilesUpdate }, executePostBankProfilesEdit] =
    useAxios(
      {
        url: "/trader/bankprofiles/edit",
        method: "POST",
      },
      axiosConfig
    );

  const handleToogleAvailable = (id, available) => {
    executePostBankProfilesEdit({
      data: {
        bankProfileId: id,
        available: available === 1 ? 0 : 1,
      },
    })
      .catch((error) => {
        // console.log("executePostStatus", error);
      })
      .finally(() => {
        setTrigger(trigger + 1);
      });
  };

  const handleModalActivity = (active = true) => {
    // setModalActive(active);
  };

  const handleModal2Activity = (active = true, device, editDitails) => {
    setDevice(device);
    setEditDitails(editDitails);
    setModal2Active(active);
  };

  const handleModal3Activity = (active = true, bankProfile) => {
    setSelectedBankProfile(bankProfile);
    setModal3Active(active);
  };

  const handleModalEditActivity = (active = true) => {
    setModalEditActive(active);
  };

  const handleModalEditBankProfile = (index) => {
    setBankProfileData(data?.bankProfiles[index]);
    setModalEditActive(true);
  };

  const handleChangeBankProfileStatus = (id) => {
    setSelectedBankProfile(id);
    setModalToArchiveActive(true);
  };

  const getBankName = (bankId) => {
    const bank = generalBankList[bankId] || null;
    if (bank) {
      return bank;
    } else {
      return "Неизвестный";
    }
  };

  useEffect(() => {
    if (!modalActive && !modalEditActive && !modal2Active) {
      executePost({
        data: {
          page: pageBankProfiles,
          count: count,
        },
      }).catch((error) => {
        // console.log("executePost", error);
      });
    }

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [
    pageBankProfiles,
    count,
    modalActive,
    modalEditActive,
    trigger,
    modal2Active,
    modalToArchiveActive,
  ]);

  let pageCountQuery = Math.ceil(data?.countBankProfiles / count);

  if (loading || loadingBankProfilesUpdate)
    return (
      <div style={{ display: "flex" }}>
        <Spinner />
      </div>
    );

  return (
    <>
      <ModalAddRequisite active={modalActive} setActive={handleModalActivity} />

      <ModalAddBankProfile
        active={modal2Active}
        setActive={handleModal2Activity}
        Device={device}
        isEdit={true}
        editDitails={editDitails}
      />
      <ModalAddRequisiteProfileNew
        active={modal3Active}
        setActive={handleModal3Activity}
        BankProfile={selectedBankProfile}
      />
      <ModalDeviceToArchive
        active={modalToArchiveActive}
        setActive={setModalToArchiveActive}
        bankProfileId={selectedBankProfile}
      />
      <div className="main__head">
        <div
          className="text text_xl"
          style={{ textShadow: "1px 3px 1px black" }}
        >
          Банки
        </div>
        {/*

        <button
          className="btn btn_add"
          id="add-project"
          onClick={handleModalActivity}
        >
          <AddShopPlusIcon />
        </button>

          */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          alignContent: "center",
        }}
      >
        {
          <AnimatedBlock>
            <table className="fixedtable">
              <thead>
                <tr>
                  <th>ЛК</th>
                  <th>Банк</th>
                  <th>Лимит</th>
                  <th>В цикле заполнено</th>
                  <th>Всего заполнено</th>
                  <th>Доступность</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {data?.bankProfiles.map(
                  (
                    {
                      id,
                      id_alias,
                      device_profile_id,
                      bank,
                      min_amount,
                      max_amount,
                      total_amount,
                      used_total_amount,
                      cycle_limit,
                      cycle_value,
                      available,
                    },
                    index
                  ) => (
                    <tr key={id}>
                      <td data-label="ЛК">{getLkAlias(bank, id_alias)}</td>
                      <td data-label="Банк">{getBankName(bank)}</td>
                      <td data-label="Лимит">
                        с {formatFinance(min_amount)} <br />
                        до {formatFinance(max_amount)} ₽
                      </td>
                      <td data-label="В цикле заполнено">
                        {formatFinance(cycle_value)} <br />
                        из {formatFinance(cycle_limit)} ₽ <br />
                        {
                          <LinearProgressBar
                            currentValue={cycle_value}
                            limitValue={cycle_limit}
                          />
                        }
                      </td>
                      <td data-label="Всего Заполнено">
                        {formatFinance(used_total_amount)} <br />
                        из {formatFinance(total_amount)} ₽ <br />
                        {
                          <LinearProgressBar
                            currentValue={used_total_amount}
                            limitValue={total_amount}
                          />
                        }
                      </td>
                      <td data-label="Доступность">
                        <ToggleButton
                          onToggle={(data) => {
                            handleToogleAvailable(id, available);
                          }}
                          initialState={available === 1 ? true : false}
                        />
                      </td>

                      <td data-label="Действия">
                        <button
                          className="btn btn-xs btn-warning btn_minimal"
                          onClick={() => {
                            handleModal2Activity(
                              true,
                              device_profile_id,
                              data?.bankProfiles[index]
                            );
                          }}
                        >
                          <EditIcon />
                        </button>

                        <button
                          className="btn btn-xs btn-success btn_minimal"
                          onClick={() => {
                            handleModal3Activity(true, id);
                          }}
                        >
                          <AddShopPlusIcon />
                        </button>

                        <button
                          className="btn btn-xs btn-draft btn_minimal"
                          onClick={() => {
                            handleChangeBankProfileStatus(id);
                          }}
                        >
                          <ArchiveIcon />
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </AnimatedBlock>
        }
      </div>
      {
        <Pagination
          page={pageBankProfiles}
          setPage={setPageBankProfiles}
          pageCountQuery={pageCountQuery}
        />
      }
    </>
  );
};

export default TradeBankProfiles;
