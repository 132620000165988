const TRADE_MODE =
  (process.env.REACT_APP_TRADE_MODE || "false") === "true" ? true : false;
const API_URL = process.env.REACT_APP_API_URL || "https://api.rosplat.cash/";
const RECAPTCHA_PUBLIC_KEY = process.env.REACT_APP_RECAPTCHA_KEY_DEV || "";
const API_DOCS_URL = process.env.REACT_APP_API_DOCS || "";
const SUPPORT_TELEGRAM_URL = process.env.REACT_APP_SUPPORT_TELEGRAM || "";
const CDN_PUBLIC_URL =
  process.env.REACT_APP_CDN_PUBLIC_URL ||
  "https://ros.fra1.digitaloceanspaces.com/";

const generalBankList = {
  100000000111: "Сбербанк",
  100000000004: "Т-Банк",
  110000000005: "Банк ВТБ",
  100000000008: "АЛЬФА-БАНК",
  100000000007: "Райффайзен",
  100000000015: "ОТКРЫТИЕ",
  100000000001: "Газпромбанк",
  100000000010: "Промсвязьбанк",
  100000000026: "УРАЛСИБ",
  100000000017: "МТС-Банк",
  100000000018: "ОТП Банк",
  100000000013: "Совкомбанк",
  100000000016: "Почта Банк",
  100000000020: "Россельхоз",
  100000000041: "БКС Банк",
  100000000150: "Яндекс Банк",
  100000000042: "КБ ЮНИСТРИМ",
  100000000030: "ЮниКредит",
  100000000265: "Цифра банк",
  100000000273: "Озон Банк",
  100000000259: "Вайлдберриз",
  100000000025: "МОСКОВСКИЙ КРЕДИТНЫЙ БАНК",
  100000000003: "Банк Синара",
  100000000043: "Газэнергобанк",
  100000000028: "АКБ АВАНГАРД",
  100000000086: "ПНКО ЭЛПЛАТ",
  100000000011: "РНКБ Банк",
  100000000044: "Экспобанк",
  100000000049: "Банк ВБРР",
  100000000095: "АБ РОССИЯ",
  100000000900: "ДБО Фактура",
  100000000056: "КБ Хлынов",
  100000000053: "Бланк банк",
  100000000121: "КБ Солидарность",
  100000000082: "Банк ДОМ.РФ",
  100000000127: "Хакасский муниципальный банк",
  100000000087: "Банк ПСКБ",
  100000000052: "Банк Левобережный",
  100000000006: "АК БАРС БАНК",
  100000000098: "КБ РостФинанс",
  100000000092: "БыстроБанк",
  100000000229: "МС Банк Рус",
  100000000027: "Кредит Европа Банк (Россия)",
  100000000080: "АКБ Алмазэргиэнбанк",
  100000000122: "ИК Банк",
  100000000124: "БАНК ОРЕНБУРГ",
  100000000118: "КБ АГРОПРОМКРЕДИТ",
  100000000159: "АКБ Энергобанк",
  100000000146: "КОШЕЛЕВ-БАНК",
  100000000069: "СДМ-Банк",
  100000000140: "МБ Банк",
  100000000047: "АКБ Абсолют Банк",
  100000000099: "КБ Модульбанк",
  100000000135: "Банк Акцепт",
  100000000139: "КБ ЭНЕРГОТРАНСБАНК",
  100000000166: "СИБСОЦБАНК",
  100000000172: "Банк Развитие-Столица",
  100000000187: "Банк РЕСО Кредит",
  100000000022: "НКО ЮМани",
  100000000029: "Банк Санкт-Петербург",
  100000000050: "КБ Кубань Кредит",
  100000000177: "Банк НОВИКОМ (НОВИКОМБАНК)",
  100000000084: "РосДорБанк",
  100000000088: "СКБ Приморья Примсоцбанк",
  100000000126: "Банк Саратов",
  100000000173: "Таврический Банк",
  100000000152: "Тольяттихимбанк",
  100000000201: "Банк Кремлевский",
  100000000034: "ТКБ БАНК",
  100000000094: "ЧЕЛЯБИНВЕСТБАНК",
  100000000235: "АКБ Держава",
  100000000134: "НБД-Банк",
  100000000091: "БАНК СНГБ",
  100000000258: "АИКБ Енисейский объединенный банк",
  100000000153: "Банк Венец",
  100000000014: "Банк Русский Стандарт",
  100000000083: "Дальневосточный банк",
  100000000170: "Банк Интеза",
  100000000037: "ГЕНБАНК",
  100000000215: "ВУЗ-банк",
  100000000142: "УРАЛПРОМБАНК",
  100000000243: "Банк Национальный стандарт",
  100000000090: "Банк Екатеринбург",
  100000000171: "МОРСКОЙ БАНК",
  100000000064: "Кредит Урал Банк",
  100000000093: "Углеметбанк",
  100000000253: "Авто Финанс Банк",
  100000000193: "КБ СТРОЙЛЕСБАНК",
  100000000158: "Банк ИТУРУП",
  100000000174: "Первый Инвестиционный Банк",
  100000000183: "Газтрансбанк",
  100000000106: "ЧЕЛИНДБАНК",
  100000000115: "НИКО-БАНК",
  100000000062: "НОКССБАНК",
  100000000058: "ВЛАДБИЗНЕСБАНК",
  100000000195: "Кузнецкбизнесбанк",
  100000000206: "Томскпромстройбанк",
  100000000203: "АКБ МЕЖДУНАРОДНЫЙ ФИНАНСОВЫЙ КЛУБ",
  100000000081: "АКБ Форштадт",
  100000000230: "АКБ Солид",
  100000000211: "Банк АЛЕКСАНДРОВСКИЙ",
  100000000107: "АКИБАНК",
  100000000185: "Нацинвестпромбанк",
  100000000113: "АКБ Алеф-Банк",
  100000000248: "КБ ВНЕШФИНБАНК",
  100000000151: "КБ Урал ФД",
  100000000129: "КБ АРЕСБАНК",
  100000000208: "Северный Народный Банк",
  100000000182: "Банк Объединенный капитал",
  100000000189: "ТАТСОЦБАНК",
  100000000202: "Норвик Банк",
  100000000071: "НС Банк",
  100000000066: "Земский банк",
  100000000154: "Банк Аверс",
  100000000194: "КБ РУСНАРБАНК",
  100000000232: "РЕАЛИСТ БАНК",
  100000000105: "Эс-Би-Ай Банк",
  100000000136: "МЕТКОМБАНК",
  100000000191: "КБЭР Банк Казани",
  100000000197: "АКБ Трансстройбанк",
  100000000205: "Банк Заречье",
  100000000059: "КБ Центр-инвест",
  100000000070: "Датабанк",
  100000000223: "СОЦИУМ-БАНК",
  100000000148: "КБ СИНКО-БАНК",
  100000000199: "ИШБАНК",
  100000000045: "Банк ЗЕНИТ",
  100000000217: "АКБ ФОРА-БАНК",
  100000000169: "МП Банк",
  100000000125: "ГОРБАНК",
  100000000176: "МОСКОМБАНК",
  100000000144: "Тимер Банк",
  100000000181: "Автоторгбанк",
  100000000078: "Ингосстрах Банк",
  100000000165: "Русьуниверсалбанк",
  100000000031: "КБ УБРиР",
  100000000226: "АКБ Приморье",
  100000000236: "Банк ИПБ",
  100000000103: "КБ Пойдём!",
  100000000175: "АКБ ТЕНДЕР-БАНК",
  100000000212: "КБ Крокус-Банк",
  100000000024: "Хоум кредит",
  100000000032: "Ренессанс Банк (Ренессанс Кредит)",
  100000000225: "УКБ Белгородсоцбанк",
  100000000272: "Хайс Банк",
  100000000219: "Севергазбанк",
  100000000184: "АКБ НРБанк",
  100000000110: "КБ Москоммерцбанк",
  100000000180: "Кубаньторгбанк",
  100000000222: "УКБ Новобанк",
  100000000233: "НК Банк",
  100000000138: "Тойота Банк",
  100000000133: "ББР Банк",
  100000000245: "АКБ Ланта-Банк",
  100000000270: "КБ Долинск",
  100000000216: "Банк Финсервис",
  100000000067: "КБ Новый век",
  100000000192: "Банк МБА-МОСКВА",
  100000000200: "АКБ СЛАВИЯ",
  100000000167: "АКБ ЕВРОФИНАНС МОСНАРБАНК",
  100000000260: "Банк БЖФ",
  100000000161: "КБ ЛОКО-Банк",
  100000000234: "БАНК МОСКВА-СИТИ",
  100000000250: "Драйв Клик Банк",
  100000000149: "ГУТА-БАНК",
  100000000278: "ФИНСТАР БАНК",
  100000000247: "банк Раунд",
  100000000228: "Прио-Внешторгбанк",
  100000000196: "Инбанк",
  100000000096: "Уралфинанс",
  100000000102: "Банк Агророс",
  100000000160: "ЮГ-Инвестбанк",
  100000000231: "ЦентроКредит",
  100000000163: "Снежинский",
  100000000040: "Банк ФИНАМ",
  100000000284: "Банк Точка",
  100000000266: "банк Элита",
  100000000046: "Металлинвестбанк",
  100000000117: "ПроБанк",
  100000000137: "Первый Дортрансбанк",
  100000000282: "ЦМРБанк",
  100000000296: "Плайт",
  100000000286: "Банк Оранжевый",
  100000000293: "ИКБР ЯРИНТЕРБАНК",
  100000000288: "РНКО Платежный конструктор",
  100000000108: "Азиатско-Тихоокеанский Банк",
  100000000198: "Экономбанк",
  100000000267: "Ставропольпромстройбанк",
  100000000271: "НКО Мобильная карта",
};
const aliasBankList = {
  100000000111: "С",
  100000000004: "Т",
  110000000005: "В",
  100000000008: "А",
  100000000007: "Р",
  100000000015: "О",
  100000000001: "Г",
  100000000010: "ПСБ",
  100000000013: "СОВ",
  100000000020: "РСХБ",
  100000000025: "МКБ",
  100000000030: "ЮНИ",
  100000000003: "СИНА",
  100000000043: "ГЭБ",
  100000000028: "АВАН",
  100000000086: "ЭЛПЛ",
  100000000011: "РНКБ",
  100000000044: "ЭКСП",
  100000000049: "ВБРР",
  100000000095: "РОСС",
  100000000900: "ФАКТ",
  100000000056: "ХЛЫН",
  100000000053: "БЛАН",
  100000000121: "СОЛИ",
  100000000082: "ДОМ",
  100000000127: "ХАКА",
  100000000017: "МТС",
  100000000087: "ПСКБ",
  100000000052: "ЛЕВО",
  100000000006: "БАРС",
  100000000098: "РОСТ",
  100000000092: "БЫСТ",
  100000000229: "МСБ",
  100000000027: "КЕБ",
  100000000080: "АЛМА",
  100000000122: "ИКБ",
  100000000124: "ОРЕН",
  100000000118: "АПКБ",
  100000000159: "ЭНЕР",
  100000000146: "КОШЕ",
  100000000069: "СДМБ",
  100000000140: "МББ",
  100000000047: "АБСО",
  100000000099: "МОДУ",
  100000000135: "АКЦЕ",
  100000000139: "ЭТБ",
  100000000166: "ССБ",
  100000000172: "РАЗВ",
  100000000187: "РЕСО",
  100000000022: "ЮМАН",
  100000000029: "БСПБ",
  100000000050: "КУБА",
  100000000177: "НОВИ",
  100000000084: "РДБ",
  100000000088: "ПСОЦ",
  100000000126: "САРА",
  100000000173: "ТАВР",
  100000000152: "ТОЛЬ",
  100000000201: "КРЕМ",
  100000000034: "ТКББ",
  100000000094: "ЧИБ",
  100000000235: "ДЕРЖ",
  100000000134: "НБДБ",
  100000000091: "СНГБ",
  100000000258: "ЕНИС",
  100000000153: "ВЕНЕ",
  100000000016: "ПОЧТ",
  100000000014: "БРС",
  100000000083: "ДВБ",
  100000000170: "ИНТЕ",
  100000000037: "ГЕНБ",
  100000000215: "ВУЗ",
  100000000142: "УПБ",
  100000000243: "НСБ",
  100000000090: "ЕКАТ",
  100000000171: "МОРС",
  100000000064: "КУБ",
  100000000093: "УМБ",
  100000000253: "АФБ",
  100000000193: "СЛБ",
  100000000158: "ИТУР",
  100000000174: "ПИБ",
  100000000183: "ГТБ",
  100000000106: "ЧИБ",
  100000000115: "НИКО",
  100000000062: "НОКС",
  100000000058: "ВБС",
  100000000195: "КББ",
  100000000206: "ТПСБ",
  100000000203: "МФКБ",
  100000000081: "ФОРТ",
  100000000230: "СБ",
  100000000211: "АЛЕК",
  100000000107: "АКИБ",
  100000000185: "НИПБ",
  100000000113: "АЛЕФ",
  100000000248: "ВФБ",
  100000000151: "УФДБ",
  100000000129: "АРЕС",
  100000000208: "СЕВЕ",
  100000000182: "БОК",
  100000000189: "ТАТС",
  100000000202: "НОРВ",
  100000000071: "НСБА",
  100000000066: "ЗЕМС",
  100000000154: "АВЕР",
  100000000194: "РНБ",
  100000000232: "РЕАЛ",
  100000000105: "ЭБАБ",
  100000000136: "МЕТК",
  100000000191: "КБЭР",
  100000000197: "ТРАН",
  100000000205: "ЗАРЕ",
  100000000059: "ЦИБ",
  100000000070: "ДАТА",
  100000000223: "СОЦИ",
  100000000148: "СИНК",
  100000000199: "ИШБ",
  100000000045: "ЗЕНИ",
  100000000217: "ФОРА",
  100000000169: "МПБ",
  100000000125: "ГОРБ",
  100000000176: "МОСК",
  100000000144: "ТИМЕ",
  100000000181: "АВТБ",
  100000000026: "УРАЛ",
  100000000078: "ИНГО",
  100000000165: "РУБ",
  100000000031: "УБРР",
  100000000226: "ПРИМ",
  100000000236: "ИПБ",
  100000000103: "ПОЙД",
  100000000175: "ТЕНД",
  100000000018: "ОТП",
  100000000212: "КРОК",
  100000000024: "ХОУМ",
  100000000032: "РЕНЕ",
  100000000225: "БГСБ",
  100000000272: "ХАЙС",
  100000000219: "СГБ",
  100000000184: "НРБ",
  100000000110: "МКОБ",
  100000000180: "КТБ",
  100000000222: "НОВО",
  100000000233: "НКБ",
  100000000138: "ТОЙТ",
  100000000133: "ББРБ",
  100000000245: "ЛАНТ",
  100000000270: "ДОЛИ",
  100000000216: "БФНС",
  100000000042: "ЮНИС",
  100000000067: "ВЕК",
  100000000192: "МБАМ",
  100000000041: "БКС",
  100000000200: "СЛАВ",
  100000000167: "ЕМНБ",
  100000000150: "ЯНДЕ",
  100000000260: "ББЖФ",
  100000000161: "ЛОКО",
  100000000234: "БМС",
  100000000250: "КЛИК",
  100000000149: "ГУТА",
  100000000278: "ФИНС",
  100000000247: "РАУН",
  100000000228: "ПВТБ",
  100000000196: "ИНБ",
  100000000096: "УФБ",
  100000000102: "АГРО",
  100000000160: "ЮГИБ",
  100000000231: "ЦКБ",
  100000000163: "СНЕЖ",
  100000000040: "ФИНА",
  100000000284: "ТОЧК",
  100000000266: "ЭЛИТ",
  100000000046: "МИБ",
  100000000117: "ПРОБ",
  100000000265: "ЦИФР",
  100000000273: "ОЗОН",
  100000000137: "ПДТБ",
  100000000282: "ЦМРБ",
  100000000296: "ПЛАЙ",
  100000000286: "ОРАН",
  100000000293: "ЯРИН",
  100000000288: "ПК",
  100000000108: "АТБ",
  100000000259: "ВББ",
  100000000198: "ЭКОН",
  100000000267: "СТАВ",
  100000000271: "МОБИ",
};

const trafficDetailsScore = {
  "-1": "Rosplat Plus",
  1: "Любой",
  2: "Вторичный",
  3: "VIP",
  51: "Rosplat",
};

export {
  TRADE_MODE,
  RECAPTCHA_PUBLIC_KEY,
  API_URL,
  API_DOCS_URL,
  SUPPORT_TELEGRAM_URL,
  CDN_PUBLIC_URL,
  generalBankList,
  trafficDetailsScore,
  aliasBankList,
};
