import React, { useState, useEffect } from "react";
import Spinner from "../spinner/Spinner";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { toast } from "react-toastify";
import { $authHost, axiosConfig } from "../../common/api";
import { isNumeric, isPhoneNumber, isCardNumber } from "../../common/utils";

// удаление

const ModalAddRequisiteProfile = ({ active, setActive }) => {
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [groupType, setGroupType] = useState("");
  const [type, setType] = useState("");
  const [phone, setPhone] = useState("");
  const [cardpan, setCardpan] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [usedTotalAmount, setUsedTotalAmount] = useState("");
  const [fio, setFio] = useState("");
  const [available, setAvailable] = useState(0);

  let errors = {
    requisite_phone: isPhoneNumber(phone)
      ? null
      : {
          message: "Допустим только номер телефона",
        },
    requisite_cardpan: isCardNumber(cardpan)
      ? null
      : {
          message: "Допустим только номер карты",
        },
    requisite_min_amount: isNumeric(minAmount)
      ? null
      : {
          message: "Допустимы только цифры",
        },
    requisite_max_amount: isNumeric(maxAmount)
      ? null
      : {
          message: "Допустимы только цифры",
        },
    requisite_total_amount: isNumeric(totalAmount)
      ? null
      : {
          message: "Допустимы только цифры",
        },
    requisite_used_total_amount: isNumeric(usedTotalAmount)
      ? null
      : {
          message: "Допустимы только цифры",
        },
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  const isError = () => {
    return (
      errors?.requisite_phone ||
      errors?.requisite_cardpan ||
      errors?.requisite_min_amount ||
      errors?.requisite_max_amount ||
      errors?.requisite_total_amount ||
      errors?.requisite_used_total_amount
    );
  };

  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ data }, refetch] = useAxios("/trader/systems", axiosConfig);

  const [{ loading }, executePost] = useAxios(
    {
      url: "/trader/requisites/create",
      method: "POST",
    },
    axiosConfig
  );

  useEffect(() => {
    if (!active) {
      setSelectedTypeId(null);
      setGroupType("");
      setType("");
      setPhone("");
      setCardpan("");
      setFio("");
      setMinAmount("");
      setMaxAmount("");
      setTotalAmount("");
      setUsedTotalAmount("");
      setAvailable(0);
    } else {
      refetch().catch((error) => {
        // console.log("refetch", error);
      });
    }
  }, [active, setPhone, setCardpan, setFio]);

  const selectDetails = () => {
    if (groupType === "card") {
      return {
        cardpan,
        fio,
      };
    } else {
      return {
        phone,
        fio,
      };
    }
  };

  const submitModal = async () => {
    await executePost({
      data: {
        type, // card1, sbp1
        requisite_details: selectDetails(), // JSON {} {"phone":"+79886662211","fio":"Test Name"}  {"cardpan":"4242424242424242","fio":"Test Name Card"}
        min_amount: minAmount, // 100
        max_amount: maxAmount, // 1000
        total_amount: totalAmount, // 10000
        used_total_amount: usedTotalAmount, // 0
        available, // 1 or 0
      },
    })
      .then((data) => {
        setActive(false);
        toast.success("Реквизиты успешно добавлены");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal6"
      onClick={() => setActive(false)}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">Добавление реквизитов</div>
          <div
            className="dialog__head-close"
            id="modal6Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div
          className="dialog__body"
          style={{
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          {data?.systems ? (
            <>
              <span>Выберите метод платежа</span>

              <ul className="select-options">
                {data?.systems?.map(({ name, id, system_group }) => (
                  <li
                    style={{ cursor: "pointer" }}
                    key={id}
                    className={
                      selectedTypeId === id
                        ? "select-option select-option_active"
                        : "select-option"
                    }
                    onClick={() => {
                      setSelectedTypeId(id);
                      setType(name);
                      setGroupType(system_group);
                    }}
                  >
                    {system_group + " - " + name}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <Spinner />
            </>
          )}

          {groupType != "" && (
            <>
              {groupType === "card" ? (
                <div className="custom-input custom-input_block">
                  <label htmlFor="requisite-cardpan">Номер карты</label>
                  <input
                    value={cardpan}
                    type="text"
                    id="requisite-cardpan"
                    autoComplete="off"
                    placeholder="4200420042004200"
                    onChange={(e) => setCardpan(e.target.value)}
                  />
                  {getErrorBlock(errors?.requisite_cardpan)}
                </div>
              ) : (
                <div className="custom-input custom-input_block">
                  <label htmlFor="requisite-phone">Номер телефона</label>
                  <input
                    value={phone}
                    type="text"
                    id="requisite-phone"
                    autoComplete="off"
                    placeholder="+79991112233"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {getErrorBlock(errors?.requisite_phone)}
                </div>
              )}

              <div className="custom-input custom-input_block">
                <label htmlFor="requisite-fio">ФИО</label>
                <input
                  value={fio}
                  type="text"
                  id="requisite-fio"
                  autoComplete="off"
                  placeholder="Введите данные"
                  onChange={(e) => setFio(e.target.value)}
                />
              </div>

              <div className="custom-input custom-input_block">
                <label htmlFor="requisite-min-amount">
                  Минимальная сумма платежа
                </label>
                <input
                  value={minAmount}
                  type="text"
                  id="requisite-min-amount"
                  placeholder="Введите данные"
                  autoComplete="off"
                  onChange={(e) => setMinAmount(e.target.value)}
                />
                {getErrorBlock(errors?.requisite_min_amount)}
              </div>

              <div className="custom-input custom-input_block">
                <label htmlFor="requisite-max-amount">
                  Максимальная сумма платежа
                </label>
                <input
                  value={maxAmount}
                  type="text"
                  id="requisite-max-amount"
                  placeholder="Введите данные"
                  autoComplete="off"
                  onChange={(e) => setMaxAmount(e.target.value)}
                />
                {getErrorBlock(errors?.requisite_max_amount)}
              </div>

              <div className="custom-input custom-input_block">
                <label htmlFor="requisite-max-amount">
                  Максимальная сумма на балансе
                </label>
                <input
                  value={totalAmount}
                  type="text"
                  id="requisite-max-amount"
                  placeholder="Введите данные"
                  autoComplete="off"
                  onChange={(e) => setTotalAmount(e.target.value)}
                />
                {getErrorBlock(errors?.requisite_total_amount)}
              </div>

              <div className="custom-input custom-input_block">
                <label htmlFor="requisite-used-total-amount">
                  Текущая сумма на балансе
                </label>
                <input
                  value={usedTotalAmount}
                  type="text"
                  id="requisite-used-total-amount"
                  placeholder="Введите данные"
                  autoComplete="off"
                  onChange={(e) => setUsedTotalAmount(e.target.value)}
                />
                {getErrorBlock(errors?.requisite_used_total_amount)}
              </div>

              <div className="custom-input custom-input_block">
                <div className="custom-switch">
                  <input
                    type="checkbox"
                    id="requisite-available"
                    checked={available === 1 ? true : false}
                    onChange={() => setAvailable(available === 1 ? 0 : 1)}
                  />
                  <label htmlFor="requisite-available">Доступность</label>
                </div>
              </div>

              <div className="mt-50">
                <button
                  disabled={loading || isError()}
                  className="btn btn-success btn_sm"
                  onClick={submitModal}
                >
                  Добавить
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalAddRequisiteProfile;
