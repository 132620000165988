import React, { useState, useEffect } from "react";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { useMask, format } from "@react-input/mask";
import { toast } from "react-toastify";
import { $authHost, axiosConfig } from "../../common/api";
import { isPhoneNumber, isAdvancedPhoneNumber } from "../../common/utils";

// добавь маску для телефона

const ModalEditDeviceProfile = ({
  active,
  setActive,
  deviceId,
  devicePhonenumber,
}) => {
  console.log(active, setActive, deviceId, devicePhonenumber);

  const [phone, setPhone] = useState("");

  let errors = {
    deviceprofile_phone:
      isPhoneNumber(phone) || isAdvancedPhoneNumber(phone)
        ? null
        : {
            message: "Допустим только номер телефона",
          },
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  const isError = () => {
    return errors?.deviceprofile_phone;
  };

  useEffect(() => {
    if (!active) {
      setPhone("");
    } else {
      setPhone(devicePhonenumber);
    }
  }, [active]);

  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: "/trader/device/profiles/edit",
      method: "POST",
    },
    axiosConfig
  );

  const handleClose = () => {
    setPhone("");
    setActive(false);
  };

  const submitModal = async () => {
    await executePost({
      data: {
        id: deviceId,
        phonenumber: phone.replace(/\s/g, ""),
      },
    })
      .then((data) => {
        toast.success("Устройство успешно изменено");
        handleClose();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  const options = {
    mask: "+_ ___ ___ ____",
    replacement: { _: /\d/ },
  };

  const inputRef = useMask(options);

  if (!active) return null;

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal6"
      onClick={handleClose}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">
            Редактирование номера устройства
          </div>
          <div
            className="dialog__head-close"
            id="modal6Close"
            onClick={handleClose}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div
          className="dialog__body"
          style={{
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          <div className="custom-input custom-input_block">
            <label htmlFor="deviceprofile-phone">Номер телефона</label>
            <input
              ref={inputRef}
              value={format(phone.replace(/\+/g, ""), options)}
              type="text"
              id="deviceprofile-phone"
              autoComplete="off"
              placeholder="+7 999 888 7777"
              onChange={(e) => setPhone(e.target.value)}
            />
            {getErrorBlock(errors?.deviceprofile_phone)}
          </div>

          <div className="mt-50">
            <button
              disabled={loading || isError()}
              className="btn btn-success btn_sm"
              onClick={submitModal}
            >
              Отправить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditDeviceProfile;
